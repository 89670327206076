.header-content {
    margin: 10px;
    display: flex;
    justify-content: space-between;
}

.header-content > div {
    margin: 7px;
}

.text-flex {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: larger;
    font-weight: bolder;
    flex-grow: 8;
}

.text-flex-left {
    font-size: large;
    font-weight: bolder;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    flex: 50%
}

.button-flex-right {
    text-align: right;
    flex: 50%
}

.text-header {
    margin: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: start;
}