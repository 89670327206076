.adduser-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.adduser-stack {
    background-color: azure;
    padding: 30px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
